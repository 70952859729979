import React from 'react';

const Servicios = () => {
  return (
    <section id="servicios" className="section-style">
      <h2>Servicios</h2>
      <div className="services-list">
        <div className="service-item">
          <h3>Mantenimiento General</h3>
          <p>Ofrecemos revisiones completas y mantenimiento para asegurar que tu vehículo esté siempre en las mejores condiciones.</p>
        </div>
        <div className="service-item">
          <h3>Reparaciones Específicas</h3>
          <p>Contamos con especialistas para cada sistema de tu auto: motor, transmisión, frenos y más.</p>
        </div>
        <div className="service-item">
          <h3>Diagnóstico por Computadora</h3>
          <p>Utilizamos tecnología avanzada para un diagnóstico preciso y rápido, identificando el problema desde la raíz.</p>
        </div>
      </div>
    </section>
  );
};

export default Servicios;
