import React from 'react';
// Importa los logos de las marcas
import logoMarca1 from '../assets/chevrolet.jpg';
import logoMarca2 from '../assets/mitsubishi.png';
import logoMarca3 from '../assets/nissan.jpg';
import logoMarca4 from '../assets/toyota.png';
import logoMarca6 from '../assets/honda.jpg';
import logoMarca7 from '../assets/hyundai.jpg';
// Repite para las demás marcas...

const Especializaciones = () => {
  return (
    <section id="especializaciones" className="section-style">
      <h2>Somos expertos en:</h2>
      <div className="logos-container">
        <img src={logoMarca1} alt="Marca 1" />
        <img src={logoMarca2} alt="Marca 2" />
        <img src={logoMarca3} alt="Marca 2" />
        <img src={logoMarca4} alt="Marca 2" />
        <img src={logoMarca6} alt="Marca 2" />
        <img src={logoMarca7} alt="Marca 2" />
        {/* Repite para las demás marcas */}
      </div>
    </section>
  );
};

export default Especializaciones;
