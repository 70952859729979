import React from 'react';
import imagen1 from '../assets/imagen4.jpeg';

const Nosotros = () => {
  return (
    <section id="nosotros" className="section-style" style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: 1 }}>
        <h2>Nosotros</h2>
        <p>Somos un taller mecánico con más de 20 años de experiencia, comprometidos con la calidad y la satisfacción del cliente.</p>
        <p>Contamos con un equipo de profesionales altamente capacitados y especializados en las principales marcas de vehículos.</p>
        <p>Visítanos y descubre por qué somos la mejor opción para el cuidado de tu auto en Quilpué y Villa Alemana.</p>
      </div>
      <div style={{ flex: 1 }}>
        <img src={imagen1} alt="Nosotros" style={{ width: '100%', height: 'auto' }} />
      </div>
    </section>
  );
};

export default Nosotros;