// MainBox.js
import React, { useState } from 'react';
import './MainBox.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import logoChat from '../assets/chat.png';

function MainBox() {
    const [input, setInput] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([
        {
            message: "Estoy aquí para ayudarte. ¿Que necesitas?  \n**Agendar consulta**  \n**Consultar precios**  \n**Diagnosticar problema**  \n**Otro**",
            sender: "ChatGPT"
        }
    ]);

    const handleChange = (event) => {
        setInput(event.target.value);
    }

    const handleSend = async (event) => {
        event.preventDefault();
        const newMessage = {
            message: input,
            sender: "user"
        }

        const newMessages = [...messages, newMessage];

        setMessages(newMessages);
        setInput('');

        await processMessageToChatGPT(newMessages);
    }

    async function processMessageToChatGPT(chatMessages) {
        let apiMessages = chatMessages.map((messageObject) => {
            let role = "";
            if (messageObject.sender === "ChatGPT") {
                role = "assistant";
            } else {
                role = "user";
            }
            return (
                { role: role, content: messageObject.message }
            )
        });

        try {
            const response = await fetch("http://localhost:5000/chat", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(apiMessages)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data) {
                setMessages([
                    ...chatMessages,
                    {
                        message: data.content,
                        sender: 'ChatGPT'
                    }
                ])
            } else {
                setMessages([
                    ...chatMessages,
                    {
                        message: 'Ha ocurrido un error',
                        sender: 'ChatGPT'
                    }
                ])
            }
        } catch (error) {
            console.error('Error:', error);
            setMessages([
                ...chatMessages,
                {
                    message: 'Has excedido el límite de 25 preguntas en 20 minutos, por favor vuelve más tarde.',
                    sender: 'ChatGPT'
                }
            ])
        }
    }

    return (
        <>
            <button className="chat-button" onClick={() => setIsOpen(!isOpen)}><img src={logoChat} alt="Marca 1" class="logoChat" /></button>
            <div className={`chat-wrapper ${isOpen ? 'open' : ''}`}>
                <div className="container">
                    <button className="close-button" onClick={() => setIsOpen(false)}>❌</button>
                    <div className="response-area">
                        {messages.map((message, index) => {
                            return (
                                <div className={message.sender === "ChatGPT" ? 'gpt-message message' : 'user-message message'} key={index}>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{message.message}</ReactMarkdown>
                                </div>
                            );
                        })}
                    </div>
                    <div className="prompt-area">
                        <input type="text" placeholder="Enviar un mensaje..." value={input} onChange={handleChange} />
                        <button className="submit" type="submit" onClick={handleSend}>Enviar</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MainBox;