import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import './ResenasGoogle.css';

const ResenasGoogle = () => {
    // Definir los comentarios predefinidos
    const reviews = [
        {
            author_name: "Virginia Von Jentschik",
            rating: 5,
            text: "Excelente atención del dueño del local, Persona Inteligente q sabe q la atención personalizada es la mejor publicidad para su local. Salí de ahí y llame inmediatamente a mi Hijo para q visitará el local.😉"
        },
        {
            author_name: "Mauricio. Cea",
            rating: 5,
            text: "Atendido por sus dueños, inmediata, profesional, amena rápida y sobretodo los mismos valores de hace 15 años, espectacular!!!"
        },
        {
            author_name: "Eduardo Rojas O'Shee",
            rating: 5,
            text: "Excelente atención y dedicación siempre. Esta vez no fue la excepción. Lo recomiendo a ojos cerrados."
        }
    ];

    // Función para renderizar las estrellas basadas en el rating
    const renderStars = (rating) => {
        let stars = [];
        for (let i = 0; i < rating; i++) {
            stars.push(<StarIcon key={i} style={{ color: 'yellow' }} />);
        }
        return stars;
    };

    return (
        <div className="resenas-container">
            {reviews.map((review, index) => (
                <Card key={index} sx={{ minWidth: 275 }} className="resena-card">
                    <CardContent>
                        <Typography variant="h5" component="div">
                            {review.author_name}
                        </Typography>
                        <Typography variant="body2">
                            Rating: {renderStars(review.rating)}
                        </Typography>
                        <Typography variant="body2">
                            {review.text}
                        </Typography>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

export default ResenasGoogle;
