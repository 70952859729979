import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Importa estilos predeterminados
import './App.css';
import Nosotros from './components/Nosotros';
import Servicios from './components/Servicios';
import Precios from './components/Precios';
import Contacto from './components/Contacto';
import ResenasGoogle from './components/ResenasGoogle';
import Especializaciones from './components/Especializaciones';
import MainBox from "./components/MainBox";
import imagen1 from "./assets/imagen1.jpeg";
import imagen2 from "./assets/imagen2.jpeg";
import imagen3 from "./assets/imagen3.jpeg";

const App = () => {
  useEffect(() => {
    ReactGA.initialize('G-E06NYDHPLX');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="app">
      <nav className="navbar">
        <h1 className="logo">Automotora Manquehue</h1>
        <ul>
          <li><a href="#nosotros">Nosotros</a></li>
          <li><a href="#servicios">Servicios</a></li>
          <li><a href="#precios">Precios</a></li>
          <li><a href="#contacto">Contacto</a></li>
        </ul>
      </nav>
      <h1><center>La calidad que tu vehículo merece</center></h1>
      <div className="carrusel">
        <Carousel autoPlay interval={3000} infiniteLoop useKeyboardArrows dynamicHeight>
          <div>
            <img src={imagen1} alt="Imagen 1" />
          </div>
          <div>
            <img src={imagen2} alt="Imagen 2" />
          </div>
          <div>
            <img src={imagen3} alt="Imagen 3" />
          </div>
        </Carousel>
      </div>
      {/* Aquí insertamos los componentes */}
      <Nosotros />
      <ResenasGoogle />
      <Servicios />
      <Especializaciones />
      <MainBox />
      <Precios />
      <Contacto />
    </div>
  );
};

export default App;
