import React from 'react';

const containerStyle = {
  width: '400px',
  height: '400px',
};

const center = {
  lat: -34.397,
  lng: 150.644,
};

const Contacto = () => {
  return (
    <section id="contacto" className="section-style">
      <h2>Contacto</h2>
      <p>Teléfono: (32) 292 8498</p>
      <p>Horario de Atención: Lunes a Viernes, 9:00 a 19:00 hrs - Sábados 9:00 a 15:00 hrs</p>
      <iframe 
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13377.34153804418!2d-71.4046973!3d-33.0476361!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9689d9cc096f5f0f%3A0x6a20e5e1ed6ec71a!2sAutomotora%20Manquehue!5e0!3m2!1ses!2scl!4v1712311204504!5m2!1ses!2scl" 
        width="100%" 
        height="400px" 
        style={{border: '0'}} 
        allowfullscreen="" 
        loading="lazy" 
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      </section>
  );
};

export default Contacto;
