import React from 'react';

const Precios = () => {
  return (
    <section id="precios" className="section-style">
      <h2>Precios</h2>
      <div className="prices-list">
        <div className="price-item">
          <h3>Servicios generales</h3>
          <p>Desde $5000 CLP</p>
        </div>
        <div className="price-item">
          <h3>Reparaciones Específicas</h3>
          <p>Varía según el trabajo</p>
        </div>
        <div className="price-item">
          <h3>Atención Mínima</h3>
          <p>$4000 CLP</p>
        </div>
      </div>
    </section>
  );
};

export default Precios;
